@import '../styles/_colors.scss';

.header {
  background-color: $header-background-color;
  padding: 10px 20px;
  // opacity: 0.8;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    color: $description-text-color;
  }

  .languages {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .contact {
    display: flex;
    gap: 16px;
  }

  .link {
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s;
    color: $description-text-color;

    &:hover {
      color: $accent-color;
    }
  }
}
.navbar {
  .bx-x {
    width: 25%;
  }

  .bx-menu{
    display: none;
  }

  .logo img{
    max-width: 50px;
  }

  .nav-links {
    height: 100%;

    .sidebar-logo{
      display: none;
    }
  }

  .links{
    display: flex;
    margin: 0;
    padding: 0;

    li{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      padding: 0 14px;

      a{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
}

@media (max-width:800px){
  nav{
    position: relative;

    .navbar {
      .bx-menu{
        font-size: 25px;
        color: #fff;
      }

      .logo{
        display: none;
      }

      .bx-menu{
        display: block;
      }

      .nav-links{
        position: fixed;
        top: 0;
        left: -100%;
        display: block;
        max-width: 270px;
        width: 50%;
        background:  $header-background-color;
        line-height: 40px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
        transition: all 0.5s ease;
        z-index: 1000;

        &.collapsed {
          left: 0;
        }
        
        .sidebar-logo{
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          background-color: rgba(255, 255, 255, 0.1);
          border-bottom: solid 1px $background-color;

          img{
            margin: 16px;
            width: 40px;
            height: 40px;
          }
        }

        .links{
          display: block;

          li{
            display: block;
            border-bottom: 1px solid $background-color;
          }
        }
      }
    }
  }
}


@media (max-width:370px){
  nav .navbar .nav-links{
  max-width: 100%;
  } 
}