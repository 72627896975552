@import '../styles/_colors.scss';

.views-wrapper {
  margin-top: 20px;
  padding: 6px 18px;
  background-color: $accent-color;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  gap: 8px;
  align-items: center;

  .views, .users {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  $spCol: #000;
  .sp {
    width: 18px;
    height: 18px;
    clear: both;
  }

  .sp-circle {
    border: 4px rgba($spCol, 0.25) solid;
    border-top: 4px rgba($spCol, 1) solid;
    border-radius: 50%;
    -webkit-animation: spCircRot 0.6s infinite linear;
    animation: spCircRot 0.6s infinite linear;
  }

  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }

  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
