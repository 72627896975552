@import 'styles/_colors.scss';

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .search-wrapper {
    width: 85%;
    max-width: 400px;
    margin-bottom: 20px;
    z-index: 1;

    input {
      width: 90%;
      padding: 12px;
      font-size: 16px;
      border: 2px solid $primary-color;
      border-radius: 30px;
      outline: none;
      background-color: #ffffff;

      &::placeholder {
        color: #888;
      }
    }
  }

  .content-wrapper {
    min-height: 64vh;

    .brands-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
      max-width: 1200px;
      margin: 0 auto;
      background-color: $background-color;
      z-index: 100;

      .data-item {
        border-radius: 10px;
        background-color: #ffffff;
        overflow: hidden;
        min-width: 250px;
        height: max-content;

        &.expanded {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.boycott-brand {
        background-color: #ab2e2e;
        }

        &.neutral-brand {
          background-color: #79797981;
        }

        .item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          cursor: pointer;
          background: linear-gradient(to right, $primary-color, $tertiary-color);
          border-bottom: 1px solid #ddd;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          clip-path: polygon(0 0, 100% 0, 100% 80%, 0 98%);
          border-bottom: unset;
          position: relative;
          color: #ffffff;
          overflow: hidden;

          span {
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
          }
          
          .drop {
            position: absolute; 
            width: 10px;
            height: 10px;
            background: #ab2e2e;
            border-radius: 50%;
            top: -20px;        
            transform: translateX(-50%);
    
            &.--1 {
              animation: drip-1 2s infinite cubic-bezier(1,0,.91,.19) 0s;
              left: 15%;
            }
      
            &.--2 {
              animation: drip-2 2s infinite cubic-bezier(1,0,.91,.19) 0.2s;
              left: 40%;
            }
      
            &.--3 {
              animation: drip-3 2s infinite cubic-bezier(1,0,.91,.19) 0.5s;
              left: 65%;
            }
      
            &.--4 {
              animation: drip-4 2s infinite cubic-bezier(1,0,.91,.19) 0.8s;
              left: 90%;
            }
      
            &.--5 {
              animation: drip-4 2s infinite cubic-bezier(1,0,.91,.19) 1.3s;
              left: 115%;
            }
          }
        
          .drop:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 15px solid rgba(171,46,46, 0.8196078431);
            top: -13px;
          }
        }
        
        @keyframes drip-1 {
          to {
            top: 190px;
          }
        }
        
        @keyframes drip-2 {
          to {
            top: 190px;
          }
        }
        
        @keyframes drip-3 {
          to {
            top: 190px;
          }
        }
        
        @keyframes drip-4 {
          to {
            top: 190px;
          }
        }

        .item-description {
          background-color: #ffffff;
          color: $text-color;
          padding: 15px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          font-size: 16px;
          position: absolute;
          z-index: 1;
          width: 270px;
          transition: max-height 0.3s ease, opacity 0.3s ease;

          .property-row {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #ddd;
            padding: 12px 0;
            position: relative;

            .property-label {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 600;
              color: $primary-color;
              margin-bottom: 5px;

              &.clickable {
                cursor: pointer;
              }
            }

            .property-value {
              color: $secondary-color;

              &.is-boycott{
                color: #008300;

                &.boycott-brand {
                  color: #ab2e2e;
                }

                &.neutral-brand {
                  color: #79797981;
                }
              }

              .info-link {
                margin: 0 8px;
                color: $secondary-color;
                text-wrap: nowrap;
              }
            }
            .suggest-alternative {
              display: flex;
              align-items: center;
              margin-top: 8px;

              .suggest-alternative-input {
                padding: 8px;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin: 0 8px;
                flex: 1;

                &:focus {
                  outline: $primary-color auto 1px;
                }
              }
              
              .suggest-alternative-button {
                padding: 8px 12px;
                background-color: $primary-color;
                color: #fff;
                border: none;
                border-radius: 4px;
                cursor: pointer;
            
                &:hover {
                  opacity: 0.9;
                }
              }
            }
          }
        }
      }
    }
  }

  .show-more {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 6px 18px;
    color: $primary-color;
    border: none;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    gap: 4px;

    &:hover {
      color: $accent-color;
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    .content-wrapper {
      .brands-wrapper {
        grid-template-columns: unset;

        .data-item {
          .item-description {
            width: 220px;
          }


          .suggest-alternative {
            .suggest-alternative-input {
              max-width: 65%;;
            }
          }
        }
      }
    }
  }
}
