@import '../styles/_colors.scss';



.site-description {
  text-align: center;
  max-width: 800px;
  margin: 18px;
  border-radius: 4px;

  p {
    font-size: 18px;
    line-height: 1.5;
    color: $text-color;
    margin: 0;
  }
}