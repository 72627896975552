@import '../styles/_colors.scss';

.footer {
  display: flex;
  flex-direction: column;
  background-color: $tertiary-color;
  color: $description-text-color;
  padding: 24px 40px;
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.5;
  
  .footer-content-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    .clarification {
      display: flex;
      flex-direction: column;
      align-items: left;
      line-height: 1.5;
      color: $description-text-color;
      max-width: 80%;

      .cretirias {
        margin: 8px 16px;
        padding: 0;
      }
    }

    .qr-code-wrapper {
      margin-right: 24px;

      .qr-code {
        max-width: 150px;
        margin: 16px 0;
      }
    }
  }

  .copy-rights-wrapper {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 24px;

    .links {
      display: flex;
      justify-content: center;
      gap: 8px;
      
      .link {
        color: $description-text-color;
      }
    }
  }


  p {
    margin: 0;
  }

  @media (max-width: 797px) {
    padding: 24px 24px;
    align-items: center;

    .footer-content-wrapper {
      flex-direction: column;
      gap: unset;
      text-align: center;

      .clarification {
        max-width: unset;
        
        .cretirias {
          text-align: left;
        }
      }

      .qr-code-wrapper {
        .qr-code {
          max-width: 130px;
        }
      }
    }

    .copy-rights-wrapper {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }
}
