@import '../styles/_colors.scss';

.no-result {
  font-size: 18px;
  color: $text-color;
  padding: 12px;

  .suggest-brand-button {
    text-decoration: underline;
    color: $primary-color;
  }

  .ml-4 {
    margin-left: 4px;
  }

  .mr-4 {
    margin-right: 4px;
  }
}